//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SparePartListCard from '@/modules/sparePart/components/sparePart-list-card.vue';
import SparePartListTable from '@/modules/sparePart/components/sparePart-list-table.vue';
import SparePartFormModal from '@/modules/sparePart/components/sparePart-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { SparePartPermissions } from '@/modules/sparePart/sparePart-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n from '@/vueI18n'

export default {
  name: 'app-sparePart-list-page',

  components: {
    [SparePartListTable.name]: SparePartListTable,
    [SparePartListCard.name]: SparePartListCard,
    [SparePartFormModal.name]: SparePartFormModal,
  },
  data() {
    return {
      isListView: false,
      dialogVisible: false,
      modalVisible: false,
      unsubscribe:undefined
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'sparePart/list/rows',
      count: 'sparePart/list/count',
      loading: 'sparePart/list/loading',
    }),
    hasPermissionToCreate() {
      return new SparePartPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  async created() {
    this.unsubscribe = await firebase.firestore().collection('sparePart').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  async mounted() {
    await this.doFilter();
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.spareParts') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'sparePart/list/doFetch',
      doFetchInBackground: 'sparePart/list/doFetchInBackground',
    }),

    displaySwitch(view) {
      switch (view) {
        case 'card':
          this.isListView = false
          break;
        case 'list':
          this.isListView = true
          break;
        default:
          break;
      }
    },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 1000);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
     i18n(key, args) {
      return this.$t(key, args);
    },

  },

};
