//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SparePartForm from '@/modules/sparePart/components/sparePart-form';
import { mapGetters, mapActions } from 'vuex';

// import Errors from '@/shared/error/errors';
// import { SparePartService } from '@/modules/sparePart/sparePart-service';

export default {
  name: 'app-sparePart-form-modal',

  props: ['visible', 'id'],

  components: {
    [SparePartForm.name]: SparePartForm,
  },

  data() {
    return {
      successDialog: false,
      // record: null,
      // saveLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      record: 'sparePart/form/record',
      findLoading: 'sparePart/form/findLoading',
      saveLoading: 'sparePart/form/saveLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      return this.i18n('entities.sparePart.new.title');
    },
    isEditing() {
      return !!this.id;
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'sparePart/form/doFind',
      doNew: 'sparePart/form/doNew',
      doUpdate: 'sparePart/form/doUpdate',
      doCreate: 'sparePart/form/doCreate',
    }),
    doCancel() {
      this.dialogVisible = false;
    },
     i18n(key, args) {
      return this.$t(key, args);
    },

    // async doSubmit(payload) {
    //   try {
    //     
    //     this.saveLoading = true;
    //     const { id } = await SparePartService.create(
    //       payload.values,
    //     );
    //     // const record = await SparePartService.find(id);
    //     // this.$emit('success', record);
    //     this.$emit('close');
    //   } catch (error) {
    //     Errors.handle(error);
    //   } finally {
    //     this.saveLoading = false;
    //   }
    // },

    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } else {
        const isCreated = await this.doCreate(payload.values);
        this.$emit('close');
        if (isCreated) {
          this.successDialog = true
        }
      }
    },
  },
};
